import axios from "axios";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        deleteId: null,
        deleteApi : null,
        deleteDialog: false,
        deleteLoading: false,
        changed: false,
    },
    getters: {
        changed: state => state.changed,
    },
    actions: {
        deletingId(context, payLoad) {
            context.commit('deletingId', payLoad)
        },
        deleteItem(context) {
            context.commit('deleteItem')
        },
    },
    mutations: {
        deletingId(state, payLoad) {
            state.deleteId = payLoad.id;
            state.deleteApi = payLoad.api;
            state.deleteDialog = true;
        },
        deleteItem(state) {
            state.deleteLoading = true;
            axios.delete(store.state.api + state.deleteApi + '/' + state.deleteId, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${store.state.account.token}`,
                },
            }).then(() => {
                store.state.notify.is = true;
                store.state.notify.color = 'orange';
                store.state.notify.text = "آیتم مورد نظر حذف شد";
                state.changed = true;
            }).catch((err) => {
                store.state.notify.is = true;
                store.state.notify.color = 'red';
                if (err.response.status === 401) {
                    this.logout();
                    store.state.notify.text = "لطفا دوباره وارد شوید";
                } else {
                    store.state.notify.text = err.response.data.result;
                }
            }).finally(() => {
                state.deleteDialog = false;
                state.deleteLoading = false;
            })
        },
    }
}