<template>
  <div class="footer">
    <v-container>
      <div  class="d-flex flex-wrap align-start mb-4">
        <div  class="col-md-4"><h3  class="titlesize white--text">دان پارک</h3>
          <div  style="color: #dedede;font-size: 14px;"><p>سامانه حمل و نقل دان پارک با هدف توسعه و پیشرفت سیستم های داخلی پایانه مرزی مهران در سال 1401 توسط شرکت دورنمای دانش فردا ایجاد شد</p></div>
        </div>
        <div  class="col-md-1"></div>
        <div  class="col-md-2 "><h3  class="titlesize white--text mb-2">ارتباط با
          ما</h3>
          <ul ><a href="#" class="">
            <li >درباره ما</li>
          </a><a  href="#" class="">
            <li >تماس با ما</li>
          </a><a  href="#" class="">
            <li >قوانین و مقررات</li>
          </a></ul>
        </div>
        <div  class="col-md-2 "><h3  class="titlesize white--text mb-2">
          دان پارک</h3>
          <ul ><a  href="#" class="">
            <li >پشتیبانی</li>
          </a><a  href="#" class="">
            <li ></li>
          </a></ul>
        </div>
        <div data-v-08abfbbe="" class="col-md-3">
          <img style="width: 100%" src="../assets/image/footer.png" />
        </div>
      </div>
      <div class=" text-center"><p class="white--text">کاری از گروه نرم افزاری <span  class="font-weight-bold">شرکت دورنمای دانش فردا</span></p></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WebFooter"
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 3px solid #7faed7;
  background: linear-gradient(#064493, #247dcb);
  min-height: 200px;
  width: 100%;

  li {
    cursor: pointer;
    color: #c6c6c6;
    transition: all .3s;
    font-size: 14px;
    list-style: none;
    &:hover {
      color: white;
    }
  }
}
</style>