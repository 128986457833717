import Vue from 'vue'
import Vuex from 'vuex'
import deleteItem from "@/store/Modules/deleteItem";

Vue.use(Vuex)
import VueCookie from 'vue-cookie'
Vue.use(VueCookie);
// let host='https://backend.danpark.ir/';
let host='https://backend.danpark.koosharayan.ir/';

// let host='http://127.0.0.1:8000/';
let Id = Vue.prototype.$cookie.get("token")?JSON.parse(Vue.prototype.$cookie.get("token")): {token:null};
let permissions=Vue.prototype.$cookie.get("permissions")?JSON.parse(Vue.prototype.$cookie.get("permissions")): {permissions:null};



export default new Vuex.Store({
  modules : {
    deleteItem
  },
  state: {



    account:{...Id,permissions : {...permissions}},


    rules: {
      required: (value,label='') => !!value ||
          'وارد کردن '+label+ ' الزامی است'
      ,
      usernamePattern: value => {
        const pattern = /^(?=.{6,20}$)(?![_.0-9])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/
        return pattern.test(value) || 'نام کاربری باید حاوی حروف مجاز و حداقل 6 کاراکتر باشد'
      },
      pattern: value => {
        const pattern = /^(\+98|0)?9\d{9}$/
        return pattern.test(value) || 'شماره تماس نا معتبر است'
      },
      min: (value,label,length) => {
        if(value){
          if(value.length>=length){
            return true;
          }
        }
        return label+ ' حداقل باید '+length+' کاراکتر باشد '
      },
      max: (value,label,length) => {
        if(value){
          if(value.length<=length){
            return true;
          }
        }
        return label+ ' حداکثر باید '+length+' کاراکتر باشد '
      },
    },

    notify : {
      is : false,
      text : '',
      color : '',
    },
    api:host+'api/',
    file:host+'upload/',
    thumbnail:host+'upload/thumbnail/',
    smallthumbnail:host+'upload/smallthumbnail/',

    lastRoute:'/',
    loading : false,
    loggedIn : false,


  },
})
