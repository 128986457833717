import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from "moment-jalaali"; // path to vuetify export
import '@mdi/font/css/materialdesignicons.css'

// Require dependencies
import VueCookie from 'vue-cookie'
import cookie from "vue-cookie";
// Tell Vue to use the plugin
Vue.use(VueCookie);


const LogOut = {
  install(Vue) {
    Vue.prototype.logout = () => {
      store.state.account.token= null;
      cookie.delete('token');
      router.push('/login');
    }
  },
}
Vue.use(LogOut)

Vue.config.productionTip = false

Vue.filter('digit', function (number) {
  return Number(Math.round(number)).toLocaleString();
});

Vue.filter('momentDate', function (date) {
  return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
});

Vue.filter('momentTDate', function (date) {
  const d = new Date(date);
  return moment(d).locale('fa').format('dddd , jYYYY/jMM/jDD , h:mm:ss a');
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
