<template>
  <v-app>
    <PageLoading v-if="$store.state.loading" style="position: absolute;position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);z-index: 999" ></PageLoading>
    <v-main >

      <v-card elevation="0" style="border-radius: 0 !important;" :style="[$store.state.loading ? {filter: 'blur(4px) grayscale(60%)'} : {}]" :disabled="$store.state.loading">

      <router-view></router-view>

      </v-card>

    </v-main>
      <v-footer class="pa-0">
        <WebFooter></WebFooter>
      </v-footer>
    <Notify></Notify>

  </v-app>
</template>

<script>
import Notify from "@/components/Notify";
import PageLoading from "@/PageLoading";
import WebFooter from "@/components/WebFooter";


export default {
  name: 'App',

  components: {
    WebFooter,
    Notify,
    PageLoading
  },

  data: () => ({
    //
  }),
};
</script>


<style lang="scss">

@import "../public/css/font.css";
@import "assets/css/normalize.css";
@import "assets/css/custom";

#app {
  font-family: "Shabnam FD" !important;
  background-color: #f8f9fd;
  position: relative;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(0 0 0 / 16%) !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(78 158 179) !important;
}

.v-btn:not(.v-btn--round).v-size--default {

  border-radius: 13px !important;
}

.v-text-field {
  border-radius: 13px !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  border-radius: 15px !important;
}
</style>
