import Vue from 'vue'
import VueRouter from 'vue-router'



const Main = resolve => {
  require.ensure(['../components/Dashboard/Components/Main.vue'], () => {
    resolve(require('../components/Dashboard/Components/Main.vue'));
  });
};

const Tickets = resolve => {
  require.ensure(['../components/Dashboard/Components/Tickets.vue'], () => {
    resolve(require('../components/Dashboard/Components/Tickets.vue'));
  });
};

const Pays = resolve => {
  require.ensure(['../components/Dashboard/Components/Pays.vue'], () => {
    resolve(require('../components/Dashboard/Components/Pays.vue'));
  });
};

const AddTrip = resolve => {
  require.ensure(['../components/Dashboard/Components/AddTrip.vue'], () => {
    resolve(require('../components/Dashboard/Components/AddTrip.vue'));
  });
};

const DriversManagement = resolve => {
  require.ensure(['../components/Dashboard/Components/DriversManagement.vue'], () => {
    resolve(require('../components/Dashboard/Components/DriversManagement.vue'));
  });
};

const TicketIssuance = resolve => {
  require.ensure(['../components/Dashboard/Components/TicketIssuance.vue'], () => {
    resolve(require('../components/Dashboard/Components/TicketIssuance.vue'));
  });
};

const Cars = resolve => {
  require.ensure(['../components/Dashboard/Components/Cars.vue'], () => {
    resolve(require('../components/Dashboard/Components/Cars.vue'));
  });
};

const Users = resolve => {
  require.ensure(['../components/Dashboard/Components/Users.vue'], () => {
    resolve(require('../components/Dashboard/Components/Users.vue'));
  });
};

const ContactUs = resolve => {
  require.ensure(['../components/Dashboard/Components/ContactUs.vue'], () => {
    resolve(require('../components/Dashboard/Components/ContactUs.vue'));
  });
};


const Terminals = resolve => {
  require.ensure(['../components/Dashboard/Components/Terminals.vue'], () => {
    resolve(require('../components/Dashboard/Components/Terminals.vue'));
  });
};

const Trips = resolve => {
  require.ensure(['../components/Dashboard/Components/Trips.vue'], () => {
    resolve(require('../components/Dashboard/Components/Trips.vue'));
  });
};

const LastTrips = resolve => {
  require.ensure(['../components/Dashboard/Components/LastTrips.vue'], () => {
    resolve(require('../components/Dashboard/Components/LastTrips.vue'));
  });
};

const SingleCoop = resolve => {
  require.ensure(['../components/Dashboard/Components/SingleCoop.vue'], () => {
    resolve(require('../components/Dashboard/Components/SingleCoop.vue'));
  });
};

const Reports = resolve => {
  require.ensure(['../components/Dashboard/Components/Reports.vue'], () => {
    resolve(require('../components/Dashboard/Components/Reports.vue'));
  });
};

const Luggages = resolve => {
  require.ensure(['../components/Dashboard/Components/Luggages.vue'], () => {
    resolve(require('../components/Dashboard/Components/Luggages.vue'));
  });
};

const Profile = resolve => {
  require.ensure(['../components/Dashboard/Components/Profile.vue'], () => {
    resolve(require('../components/Dashboard/Components/Profile.vue'));
  });
};

const Settings = resolve => {
  require.ensure(['../components/Dashboard/Components/Settings.vue'], () => {
    resolve(require('../components/Dashboard/Components/Settings.vue'));
  });
};

const Dashboard = resolve => {
  require.ensure(['../components/Dashboard/Dashboard.vue'], () => {
    resolve(require('../components/Dashboard/Dashboard.vue'));
  });
};

const LoginWeb = resolve => {
  require.ensure(['../components/Login/loginWeb.vue'], () => {
    resolve(require('../components/Login/loginWeb.vue'));
  });
};

const NotFound = resolve => {
  require.ensure(['../components/NotFound.vue'], () => {
    resolve(require('../components/NotFound.vue'));
  });
};

const StatementPage = resolve => {
  require.ensure(['../components/Dashboard/Components/Trips/StatementPage.vue'], () => {
    resolve(require('../components/Dashboard/Components/Trips/StatementPage.vue'));
  });
};

import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (store.state.account.token) {
        next();
      } else {
        next('/login');
      }
    },
    children : [
      {
        path: '/',
        name: 'MainDashboard',
        component: Main
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        beforeEnter(to, from, next) {
          if (store.state.account.token) {
            next();
          } else {
            next('/login');
          }
        },
      },
      {
        path: 'tickets',
        name: 'Bills',
        component: Tickets,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Ticket) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'pays',
        name: 'Pays',
        component: Pays,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Financial) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'trips',
        name: 'Trips',
        component: Trips,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Trip) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'last-trips',
        name: 'LastTrips',
        component: LastTrips,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Trip) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-trip/:id?',
        name: 'addTrip',
        component: AddTrip,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Trip) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'drivers-management',
        name: 'DriversManagement',
        component: DriversManagement,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Driver) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'ticket-issuance/:id?',
        name: 'TicketIssuance',
        component: TicketIssuance,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Ticket) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'single-coop',
        name: 'SingleCoop',
        component: SingleCoop,
        beforeEnter(to, from, next) {
          if (store.state.account.cooperative_id) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'cars',
        name: 'Cars',
        component: Cars,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Car) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'luggages',
        name: 'Luggages',
        component: Luggages,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Luggages) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'settings',
        name: 'Settings',
        component: Settings,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Settings) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'users',
        name: 'Users',
        component: Users,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_User) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'contact-us',
        name: 'ContactUs',
        component: ContactUs
      },{
        path: 'terminals',
        name: 'Terminals',
        component: Terminals,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Cooperative) {
            next();
          } else {
            next('/');
          }
        },
      },{
        path: 'reports',
        name: 'Reports',
        component: Reports,
        beforeEnter(to, from, next) {
          if (store.state.account.permissions.View_Reports) {
            next();
          } else {
            next('/');
          }
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginWeb,
    beforeEnter(to, from, next) {
      if (!store.state.account.token) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/statement-page',
    name: 'StatementPage',
    component: StatementPage,
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  },
  base: process.env.BASE_URL,
  routes
})

export default router
