<template>
  <div>
    <v-snackbar
        centered
        :color="$store.state.notify.color"
        v-model="$store.state.notify.is"
        :vertical="true"
        shaped
        timeout="4000"
    >
      <p class="ma-0 mt-2 text-right" >
        {{text}}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="$store.state.notify.is = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "NotifyComp",
  computed: {
    text() {
      return this.$store.state.notify.text;
    },
  },
}
</script>

<style lang="scss">

.v-snack__content {

  margin-right: 0;

}

</style>