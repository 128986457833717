import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fa from 'vuetify/lib/locale/fa';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#225c84',
                secondary: '#307cb1',
                accent: '#087e7e',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
    lang: {
        locales: { fa },
        current: 'fa',
    },
});
