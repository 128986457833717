<template>
<div>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="translate(50 50)">
      <g transform="scale(0.7)">
        <g transform="translate(-50 -50)">
          <g>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.7575757575757576s"/>
            <path fill-opacity="0.8" fill="#e15b64" d="M50 50L50 0A50 50 0 0 1 100 50Z"/>
          </g>
          <g>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102s"/>
            <path fill-opacity="0.8" fill="#f47e60" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)"/>
          </g>
          <g>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.5151515151515151s"/>
            <path fill-opacity="0.8" fill="#f8b26a" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)"/>
          </g>
          <g>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="3.0303030303030303s"/>
            <path fill-opacity="0.8" fill="#abbd81" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)"/>
          </g>
        </g>
      </g>
    </g>
    <!-- [ldio] generated by https://loading.io/ --></svg>
  <p style="text-align: center;color: #0b2977;background-color: white">لطفا کمی صبر کنید ...</p>
</div>
</template>

<script>
export default {
  name: "PageLoading"
}
</script>

<style scoped>

</style>